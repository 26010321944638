import React from "react";
import Layout from "../Components/Layout/Layout";

const info = () => {
  return (
    <Layout>
      <div className="short-bio">
        <p>
          Hi! My name is Michelangelo, and I'm a Web Developer
          <br />
          <br />
          currently full-time Front-End Engineer @{" "}
          <a href="https://www.be-tse.it/it/">Be Digitech Solutions</a>.
          <br />
          <br />
          previously:
          <br />
          Front-End Developer @{" "}
          <a href="https://www.nautes.com/">Nautes</a>.
          <br />
          tutor @{" "}
          <a href="https://www.accademiadiurbino.it/">
            Accademia di Belle Arti di Urbino
          </a>
          .
          <br />
          <br />
          Take a look at my{" "}
          <a href="http://portfolio.michelangeloalesi.it">portfolio</a>.
        </p>
      </div>
    </Layout>
  );
};

export default info;
